import "./App.css";
import Form from "./components/Form";

const App = () => {
  return (
    <div className="App">
      <Form></Form>
    </div>
  );
};

export default App;
